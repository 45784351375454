import Box from '@/common/components/Box';
import Select from '@/common/components/Select';
import TextField from '@/common/components/TextField';
import { userStatusTranslation } from '@/common/constants/userStatus.constants';
import { dateTransformer } from '@/common/utils/dateTransformer';
import { formatCpf } from '@/common/utils/formatters';
import { useClientUser } from '@/features/users/hooks/useClientUser';
import { useParams } from 'react-router-dom';

const GeneralInformationTab = () => {
  const { id: userId } = useParams();

  const { data: clientUserData } = useClientUser({
    id: userId || '',
  });

  return (
    <>
      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto max-h-[calc(100vh-128px)]">
        <p className="text-lg font-bold">Dados pessoais</p>
        <div className="flex gap-2 w-full justify-between">
          <TextField
            label="CPF"
            className="w-3/4"
            value={
              clientUserData?.cpf
                ? formatCpf(clientUserData?.cpf)
                : ''
            }
            disabled
          />
          <TextField
            label="Data de nascimento"
            className="w-1/4"
            value={
              clientUserData?.date_of_birth
                ? dateTransformer(clientUserData?.date_of_birth)
                : ''
            }
            disabled
          />
        </div>
        <TextField
          label="Nome"
          value={clientUserData?.full_name}
          disabled
        />
        <Select
          label="Cargo(s)"
          value={clientUserData?.user_roles?.map(({ role }) => ({
            label: role.display_name,
            value: role.id,
          }))}
          isMulti
          isDisabled
        />
        <TextField
          label="Status"
          value={
            userStatusTranslation[
            (clientUserData?.status ||
              'default') as keyof typeof userStatusTranslation
            ]
          }
          disabled
        />
        <TextField
          label="Matrícula"
          value={clientUserData?.enrollment_number}
          disabled
        />
        <TextField
          label="Telefone"
          value={clientUserData?.phone?.number}
          disabled
        />
        <TextField
          label="Email"
          value={clientUserData?.email}
          disabled
        />
      </Box>
    </>
  );
};

export default GeneralInformationTab;

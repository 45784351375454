import ListFilters from "@/common/components/ListFilters";
import ListHeader from "@/common/components/ListHeader";
import TextField from "@/common/components/TextField";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import { CarFront, SearchIcon } from "lucide-react";
import { useEquipments } from "../../hooks/useEquipments";
import { CompanyEquipmentData, EquipmentCategoryData } from "../../types/equipments.type";
import { twMerge } from "tailwind-merge";
import { Pagination } from "@/common/components/Table/pagination";
import { EquipmentsGridLoading } from "../../components/EquipmentGridLoading";
import { EquipmentsGridItem } from "../../components/EquipmentGridItem";
import { SheetFilters } from "@/common/components/SheetFilters";
import { equipmentStatusTranslation } from "@/common/constants/equipmentStatus.constants";
import { useEquipmentCategories } from "../../hooks/useEquipmentCategories";
import { Mapper } from "@/common/services/mapper";
import useDebounce from "@/common/hooks/useDebounce";
import { options as propertyKindOptions } from "@/features/equipments/constants/propertyKindOptions.constant";
import { options as usageKindOptions } from "@/features/equipments/constants/usageKindOptions.constant";

const EquipmentsList = () => {
  const { filterParams, setFilterParams } = useFilterParams();

  const [searchEquipmentCategory, setSearchEquipmentCategory] = useDebounce('');

  const { data: companyEquipments, meta, isPending: isPendingCompanyEquipments } = useEquipments<CompanyEquipmentData[]>({
    filters: {
      search: filterParams.search,
      page: filterParams.page,
      status: filterParams.status?.value,
      categoryId: filterParams.category?.value,
      usageKind: filterParams.usage_kind,
      propertyKind: filterParams.property_kind,
    },
  });

  const { data: equipmentCategoryOptions } = useEquipmentCategories({
    filters: { search: searchEquipmentCategory },
    mapper: Mapper.mapToOptions<EquipmentCategoryData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  });

  return (
    <div className="flex flex-col gap-6">
      <ListHeader
        Icon={<CarFront className="w-10 h-10" strokeWidth={1.5} />}
        title="Equipamentos"
        createButtonText="Novo equipamento"
      />

      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <TextField
            LeftIcon={<SearchIcon />}
            placeholder="Pesquisar"
            className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
            defaultValue={filterParams.search || ''}
            onChange={(e: any) => setFilterParams((prev) => ({ ...prev, search: e.target?.value }))}
          />

          <div className="flex gap-2 items-center justify-start">
            <ListFilters
              filters={[
                {
                  className: '',
                  onChange: (e) => setFilterParams((prev) => ({ ...prev, category: e })),
                  onInputChange: setSearchEquipmentCategory,
                  options: [{ label: 'Todas', value: '' }, ...(equipmentCategoryOptions || [])],
                  value: filterParams.category || '',
                  placeholder: 'Tipo de Equipamento',
                },
                {
                  className: '',
                  onChange: (e) => setFilterParams((prev) => ({ ...prev, status: e })),
                  options: [{ label: 'Todas', value: '' }, ...Object.entries(equipmentStatusTranslation).map(([key, value]) => ({ label: value, value: key }))],
                  value: filterParams.status || '',
                  placeholder: 'Situação',
                },
              ]}
            />

            <SheetFilters
              filters={[
                {
                  label: 'Tipo de Equipamento',
                  options: [
                    {
                      label: 'Todos',
                      value: '',
                      selected: filterParams.category?.value === '',
                      onClick: () => setFilterParams((prev) => ({ ...prev, category: '' })),
                    },
                    ...(equipmentCategoryOptions?.map(({ label, value }) => ({
                      label,
                      value,
                      selected: filterParams.category?.value === value,
                      onClick: () => setFilterParams((prev) => ({ ...prev, category: { value, label } })),
                    })) || [])
                  ]
                },
                {
                  label: 'Situação',
                  options: [
                    {
                      label: 'Todos',
                      value: '',
                      selected: filterParams.status?.value === '',
                      onClick: () => setFilterParams((prev) => ({ ...prev, status: '' })),
                    },
                    ...Object.entries(equipmentStatusTranslation).map(([key, value]) => ({
                      label: value,
                      value: key,
                      selected: filterParams.status?.value === key,
                      onClick: () => setFilterParams((prev) => ({ ...prev, status: { value: key, label: value } }))
                    }))
                  ]
                },
                {
                  label: 'Dias sem compra',
                  options: [
                    {
                      label: 'Não implementado',
                      value: '',
                    }
                  ]
                },
                {
                  label: 'Tipo de uso',
                  options: [
                    {
                      label: 'Todos',
                      value: '',
                      selected: filterParams.usage_kind === '',
                      onClick: () => setFilterParams((prev) => ({ ...prev, usage_kind: '' }))
                    },
                    ...usageKindOptions.map(({ label, value }) => ({
                      label,
                      value,
                      selected: filterParams.usage_kind === value,
                      onClick: () => setFilterParams((prev) => ({ ...prev, usage_kind: value }))
                    }))
                  ]
                },
                {
                  label: 'Tipo de propriedade',
                  options: [
                    {
                      label: 'Todos',
                      value: '',
                      selected: filterParams.property_kind === '',
                      onClick: () => setFilterParams((prev) => ({ ...prev, property_kind: '' }))
                    },
                    ...propertyKindOptions.map(({ label, value }) => ({
                      label,
                      value,
                      selected: filterParams.property_kind === value,
                      onClick: () => setFilterParams((prev) => ({ ...prev, property_kind: value }))
                    }))
                  ]
                },
              ]}
            />
          </div>
        </div>

        <div>
          <div className="grid grid-cols-[repeat(auto-fill,minmax(216px,1fr))] gap-4 overflow-y-auto max-h-[calc(100vh-140px-96px-3rem)] pb-4">
            {
              isPendingCompanyEquipments ?
                Array(20).fill(0).map((_, index) => (
                  <EquipmentsGridLoading key={index} />
                ))
                :
                <>
                  {companyEquipments.map((equipment) => (
                    <EquipmentsGridItem
                      key={equipment.id}
                      equipment={equipment}
                    />
                  ))}
                </>
            }
          </div>
          <div className={twMerge("w-full px-4 py-2 bg-black-300 rounded-lg flex justify-between items-center sticky bottom-0 shadow-[0px_0px_8px_4px_rgba(0,_0,_0,_0.2)]", typeof meta.total === 'undefined' && 'justify-end')}>
            {typeof meta.total !== 'undefined'
              ? <p className="text-xs italic font-light">Mostrando {companyEquipments.length} resultados de {meta.total}</p>
              : null
            }
            <Pagination currentPage={filterParams.page} setPage={(page) => setFilterParams((prev) => ({ ...prev, page }))} totalPages={meta.total_pages} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipmentsList;

import {
  Accordion as AccordionBase,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/common/components/ui/accordion";
import { createRef } from "react";
import { twMerge } from "tailwind-merge";

export interface AccordionProps {
  head: JSX.Element | string;
  body: JSX.Element | string;
  className?: string;
  triggerClassName?: string;
  contentClassName?: string;
  onToggle?: (isOpen: boolean) => void;
}

const Accordion = ({
  head,
  body,
  className,
  triggerClassName,
  contentClassName,
  onToggle,
}: AccordionProps) => {
  const contentRef = createRef<HTMLDivElement>();

  const defaultOnToggle = (isOpen: boolean) => {
    if (isOpen) {
      (contentRef.current as HTMLDivElement)?.classList.remove("hidden");
      setTimeout(() => (contentRef.current as HTMLDivElement)?.classList.add("overflow-visible"), 300);
    } else {
      setTimeout(() => (contentRef.current as HTMLDivElement)?.classList.add("hidden"), 100);
      (contentRef.current as HTMLDivElement).classList?.remove("overflow-visible");
    }
  };

  const handleOnToggle = (itemName: string) => {
    defaultOnToggle(!!itemName);

    onToggle && onToggle(!!itemName);
  };

  return (
    <AccordionBase
      type="single"
      collapsible
      className={twMerge("w-full p-4 rounded-sm bg-white-100", className)}
      onValueChange={handleOnToggle}
    >
      <AccordionItem value="item-1" >
        <AccordionTrigger className={twMerge("py-4", triggerClassName)}>{head}</AccordionTrigger>
        <AccordionContent ref={contentRef} containerClassName={contentClassName} >
          {body}
        </AccordionContent>
      </AccordionItem>
    </AccordionBase>
  );
};

export default Accordion;

import { unities } from "@/common/constants/unities.constants";
import { ClientConsumptionData } from "@/common/types/client/consumption.type";
import { formatCurrency, formatUnit } from "@/common/utils/formatters";

type ConsumptionEventableProps = {
  data: ClientConsumptionData;
};

const ConsumptionDetails = ({ data: consumption }: ConsumptionEventableProps) => {

  return (
    <div className="flex flex-col gap-3">
      <div className='flex gap-2'>
        <p className="italic">Empresa cliente:</p>
        <p className='font-bold'>{consumption?.client_branch?.general_information?.fantasy_name || '--'}</p>
      </div>
      <div className="flex flex-col justify-center">
        {consumption?.line_items.map((line_item) => (
          <p key={line_item.id}>
            {line_item.product.display_name} - {formatUnit(line_item.quantity, line_item.product.unit_for_quantity)} - {formatCurrency(Number(line_item.amount) / Number(line_item.quantity))}/{unities[line_item.product.unit_for_quantity]}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ConsumptionDetails;

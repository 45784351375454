import usePrivateAxios from '@/common/api/usePrivateAxios';
import { transformNewRoles } from '@/common/utils/roleTransformers';
import { removeSpecialCharacters } from '@/common/utils/unFormatters';
import { ClientUserValidationSchema } from '@/features/users/schemas/create.schema';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

interface UseCreateClientUserProps {
  onSuccess: (response: AxiosResponse) => void;
  onError: (error: AxiosError) => void;
}

export const useCreateClientUser = ({
  onSuccess,
  onError,
}: UseCreateClientUserProps) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['createClientUser'],
    mutationFn: async (form: ClientUserValidationSchema) => {
      const payload = {
        ...form,
        cpf: removeSpecialCharacters(form.cpf),
        user_roles_attributes: form?.user_roles?.length
          ? form.user_roles.map(transformNewRoles)
          : [],
        status: form?.status?.value,
        phone_attributes: form?.phone?.number
          ? {
            number: form?.phone?.number ? removeSpecialCharacters(form.phone.number) : undefined,
          }
          : undefined,
      };

      return privateAxios.postForm('/users', { client_user: payload });
    },
    onSuccess,
    onError,
  });
};

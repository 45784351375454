import { getCompanyDomain } from "@/common/services/companyDomainGetSet";
import { useAuth } from "@/common/stores/auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PublicCompanyRoute = () => {
  const location = useLocation();
  const companyDomain = getCompanyDomain();
  const { accessToken } = useAuth();

  return companyDomain ? accessToken ? <Navigate to='/company' state={{ from: location }} /> : <Outlet /> : <Navigate to='/' state={{ from: location }} />;
};

export default PublicCompanyRoute;

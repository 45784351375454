import Select from "@/common/components/Select";
import SelectController from "@/common/components/SelectController";
import TextField from "@/common/components/TextField";
import useDebounce from "@/common/hooks/useDebounce";
import { SearchIcon } from "lucide-react";
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { EquipmentValidationSchema } from "../../schemas/create.schema";
import { EquipmentModelData } from "../../types/equipments.type";
import { useEquipmentModels } from "../../hooks/useEquipmentModels";
import { unitStrategy, unitTranslation } from "@/common/constants/unitStrategies.contants";
import { options as propertyKindOptions } from "../../constants/propertyKindOptions.constant";
import { options as usageKindOptions } from "../../constants/usageKindOptions.constant";
import { FieldComponent } from "./FieldComponent";
import { UnitTextField } from "@/common/components/UnitTextField";

interface EquipmentDetailsProps {
  control: Control<EquipmentValidationSchema, any>;
  register: UseFormRegister<EquipmentValidationSchema>;
  watch: UseFormWatch<EquipmentValidationSchema>;
  setValue: UseFormSetValue<EquipmentValidationSchema>;
  errors: FieldErrors<EquipmentValidationSchema>;
  disabled?: boolean;
}

export const EquipmentDetails = ({
  control,
  register,
  watch,
  setValue,
  errors,
  disabled,
}: EquipmentDetailsProps) => {
  const [searchEquipmentModel, setSearchEquipmentModel] = useDebounce('');

  const unitForConsumption = watch('equipment_model.unitForConsumption');

  const equipmentModelFields = watch('equipment_model.requiredFieldNames') || [];

  const {
    data: equipmentModels
  } = useEquipmentModels<EquipmentModelData[]>({
    filters: { search: searchEquipmentModel },
  });

  return (
    <>
      <p>Detalhes do Equipamento</p>
      <Select
        name="equipment_model"
        label="Busque por seu modelo por Código Fipe, Marca, Nome e ano"
        placeholder={(<SearchIcon />)}
        markAsRequired
        onInputChange={(newValue) => setSearchEquipmentModel(newValue)}
        defaultValue={watch('equipment_model')}
        options={equipmentModels.length
          ? equipmentModels.map((equipmentModel) => ({
            label: equipmentModel.display_name,
            value: equipmentModel.id,
            category: equipmentModel.category.display_name,
            requiredFieldNames: equipmentModel.category.required_field_names?.map((requiredFieldName) => ({ requiredFieldName })),
            unitForConsumption: equipmentModel.category.unit_for_consumption,
          }))
          : []
        }
        onChange={(selectedOption) => {
          setValue('equipment_model', selectedOption as EquipmentValidationSchema['equipment_model']);
        }}
        isDisabled={disabled}
      />
      {errors.equipment_model ? <span className="text-danger-soft text-sm mt-[-14px]">{errors.equipment_model.message}</span> : null}
      <TextField
        label="Categoria do equipamento"
        value={watch('equipment_model.category')}
        disabled
      />
      <div className="flex w-full gap-x-2">
        <Controller
          control={control}
          name="tank_capacity"
          disabled={disabled}
          render={({ field }) => (
            <UnitTextField
              label="Capacidade do tanque"
              unit={unitStrategy['liters']}
              markAsRequired
              className="w-full"
              errorMessage={errors.tank_capacity?.message || undefined}
              {...field}
            />
          )}
        />
        {unitForConsumption && (
          <UnitTextField
            decimals={0}
            label={unitTranslation[unitForConsumption as keyof typeof unitTranslation]}
            unit={unitStrategy[unitForConsumption as keyof typeof unitStrategy]}
            markAsRequired
            className="w-full"
            errorMessage={errors.last_usage_value?.message || undefined}
            disabled={true}
            defaultValue={watch('last_usage_value') || '0'}
          />
        )}
      </div>
      <div className="grid w-full grid-cols-2 gap-x-2 gap-y-4">
        {equipmentModelFields.map((field) => (
          <div key={field.requiredFieldName}>
            <FieldComponent
              fieldName={field.requiredFieldName}
              register={register}
              errors={errors}
              disabled={disabled}
            />
          </div>
        ))}
      </div>
      <SelectController
        control={control}
        name="usage_kind"
        label="Tipo de uso"
        markAsRequired
        options={usageKindOptions}
        errorMessage={errors.usage_kind?.message || undefined}
        isDisabled={disabled}
      />
      <div className="flex gap-x-2">
        <SelectController
          control={control}
          name="property_kind"
          label="Tipo de propriedade"
          className="w-full"
          options={propertyKindOptions}
          errorMessage={errors.property_kind?.message || undefined}
          isDisabled={disabled}
        />
        <TextField
          label="Código proprietário"
          maxLength={64}
          className="w-full"
          {...register('property_code')}
          errorMessage={errors.property_code?.message || undefined}
          disabled={disabled}
        />
      </div>
    </>
  );
};

import { cn } from "@/common/utils/cn";
import { VariantProps } from "class-variance-authority";
import { buttonVariants } from "./styles";
import { forwardRef } from "react";

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  variant,
  className,
  disabled = false,
  type = 'button',
  ...props
}, ref) => {
  return (
    <button
      className={cn(buttonVariants({ variant: variant }), className, disabled ? 'opacity-50 pointer-events-none' : '')}
      type={type}
      ref={ref}
      {...props}
    />
  );
});
Button.displayName = "Button";

export default Button;

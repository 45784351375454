import Login from "./features/auth/pages/Login";
import companyDomain from "./features/auth/pages/CompanyDomain";
import RecoverCompanyUrl from "./features/auth/pages/RecoverCompanyUrl";
import UserList from './features/users/pages/List';
import UserCreate from './features/users/pages/Create';
import UserShow from './features/users/pages/Show';
import UserUpdate from './features/users/pages/Update';
import Building from "./common/Layout/building";
import CompanyDashboard from "./features/company/pages/Dashboard";
import CompanyProfile from "./features/company/pages/Profile";
import EquipmentsList from "./features/equipments/screens/List";
import EquipmentsCreate from "./features/equipments/screens/Create";

export const publicRoutes = [
  { path: '/', comp: companyDomain },
  { path: '/recover-company-domain', comp: RecoverCompanyUrl },
];

export const publicCompanyRoutes = [
  { path: '/login', comp: Login },
];

export const privateCompanyRoutes = [
  // general data
  { path: '/company', comp: CompanyDashboard },
  { path: '/company/mine', comp: CompanyProfile },
  { path: '/company/mine/contract', comp: Building },

  // structure
  { path: '/structure', comp: Building },
  { path: '/structure/create', comp: Building },
  { path: '/structure/:id/edit', comp: Building },
  { path: '/structure/:id/*', comp: Building },

  // finacial
  { path: '/billing-events', comp: Building },
  { path: '/movements-totalizer', comp: Building },
  { path: '/billing-bills', comp: Building },

  // establishment
  { path: '/establishments', comp: Building },
  { path: '/establishments/:id/*', comp: Building },

  // establishment
  { path: '/equipments', comp: EquipmentsList },
  { path: '/equipments/create', comp: EquipmentsCreate },
  { path: '/equipments/:id/edit', comp: Building },
  { path: '/equipments/:id/*', comp: Building },

  // users
  { path: '/users', comp: UserList },
  { path: '/users/create', comp: UserCreate },
  { path: '/users/:id/edit', comp: UserUpdate },
  { path: '/users/:id/*', comp: UserShow },
];

import { useCallback, useRef, useState } from "react";
import TextField from "@/common/components/TextField";
import { ArrowLeftToLine, ArrowRightToLine, LogOut, Search, UserIcon } from "lucide-react";
import Accordion from "./Accordion";
import {
  Accordion as AccordionBase
} from '@/common/components/ui/accordion';
import routes from "./routes";
import { Link, useLocation } from "react-router-dom";
import Button from "./Button";
import { useMenu } from "@/common/stores/menu";
import { twMerge } from "tailwind-merge";
import IconButton from "@/common/components/IconButton";
import Spinner from "@/common/components/Spinner";
import useLogOut from "@/common/hooks/useLogOut";
import useUser from "@/common/hooks/useUser";
import { useAppDetails } from "@/common/hooks/useAppDetails";

const Sidebar = () => {
  const location = useLocation();
  const { collapse, updateCollapse } = useMenu();
  const searchInput = useRef<HTMLInputElement>(null);
  const [accordionValue, setAccordionValue] = useState<string>('');

  const { commitSha } = useAppDetails();

  const { data: user, isLoading } = useUser({ id: 'me', staleTime: 1000 * 60 * 60 * 24 });

  const { logOut, isPending } = useLogOut();

  const onClickSearchButton = () => {
    updateCollapse(false);
    setTimeout(() => {
      searchInput.current?.focus();
    }, 50);
  };

  const isActiveRoute = useCallback((path: string) => {
    const currentPath = location.pathname.split('/')[1]?.toLowerCase().trim();
    const targetPath = path.split('/')[1]?.toLowerCase().trim();

    return currentPath === targetPath;
  }, [location.pathname]);

  return (
    <div
      className={twMerge("relative h-screen w-[283px] left-0 bg-white-200 shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.2)] transition-all duration-150", collapse && 'w-[88px]')}
    >
      <div className="flex flex-col gap-4">
        <div className={twMerge("flex flex-col gap-4 my-2 items-center", !collapse && 'hidden')}>
          <IconButton
            Icon={<ArrowRightToLine strokeWidth={1} />}
            onClick={() => updateCollapse(false)}
          />
          <img src="/ReducedLogo.svg" className="w-[18px] h-auto" />
          <IconButton
            onClick={onClickSearchButton}
            Icon={<Search strokeWidth={1} />}
          />
        </div>
        <div className={twMerge("flex flex-col gap-4", collapse && 'hidden')}>
          <div className="flex justify-between pt-6 px-6">
            <img src="/Logo.svg" className="w-[83px] h-auto" />
            <IconButton
              Icon={<ArrowLeftToLine strokeWidth={1} />}
              onClick={() => updateCollapse(true)}
            />
          </div>
          <div className="px-6">
            <TextField
              ref={searchInput}
              LeftIcon={<Search className="-translate-y-1 translate-x-1" size='18px' />}
              placeholder="Pesquisar"
              className="text-xs"
            />
          </div>
        </div>
        <AccordionBase type="single" collapsible onValueChange={(value) => setAccordionValue(value)}>
          <div className={twMerge("h-[calc(100vh-270px)] shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.1)_inset] overflow-scroll no-scrollbar", collapse && 'flex flex-col items-center gap-4 max-h-[calc(100vh-380px)] pt-4')}>
            {routes.map((route, idx) => {
              if (typeof route.path !== 'string') {
                if (collapse) {
                  return (<Link to={route.path[0].path} key={route.path[0].path}>
                    <Button
                      Icon={route.Icon}
                      label={route.label}
                      active={!!route.path.find(({ path }) => isActiveRoute(path))}
                    />
                  </Link>);
                }

                return (
                  <Accordion
                    key={idx}
                    opened={accordionValue === `${idx}`}
                    selected={!!route.path.find(({ path }) => isActiveRoute(path))}
                    value={`${idx}`}
                    head={
                      <Button
                        key={`${idx}`}
                        Icon={route.Icon}
                        label={route.label}
                        active={false}
                        showDot={!!route.path.find(({ path }) => isActiveRoute(path))}
                        accordion
                      />
                    }
                    body={(
                      <div>
                        {route.path.map((innerRoute) => (
                          <Link to={innerRoute.path} key={innerRoute.path as string}>
                            <Button
                              Icon={innerRoute.Icon}
                              label={innerRoute.label}
                              active={isActiveRoute(innerRoute.path)}
                            />
                          </Link>
                        ))}
                      </div>
                    )}
                  />
                );
              }
              return (
                <Link to={route.path} key={route.path}>
                  <Button
                    key={route.path}
                    Icon={route.Icon}
                    label={route.label}
                    active={isActiveRoute(route.path)}
                  />
                </Link>
              );
            })}
          </div>
        </AccordionBase>

      </div>
      <div className={twMerge("absolute w-full bottom-0 p-4 flex flex-col gap-4 bg-white-200 shadow-[4px_0px_16px_0px_rgba(0,0,0,0.5)]", collapse && 'items-center')}>
        {collapse
          ? (
            <img src="/ReducedAvantsoft.svg" className="w-[24px] h-auto" />
          )
          : (
            <div className="flex items-center justify-between">
              <div className="flex flex-col items-start">
                <span className="text-[10px] italic font-light">Desenvolvido por</span>
                <img src='/Avantsoft.svg' />
              </div>
              <p className="font-light text-[10px] italic">
                Versão: <span className="font-bold not-italic">{commitSha}</span>
              </p>
            </div>
          )
        }
        <div className={twMerge("flex justify-between items-center", collapse && 'flex-col gap-4')}>
          <div className="flex gap-2 items-center">
            {isLoading || !user?.profile_picture.url ? (
              <div className="flex justify-center items-center size-8 bg-white-100 rounded-full">
                <UserIcon />
              </div>
            ) : (
              <img src={user?.profile_picture.url} className="rounded-full size-8" />
            )}
            {!collapse && <div className="flex flex-col">
              <p className="text-sm font-bold max-w-40 text-ellipsis text-nowrap overflow-hidden">
                {isLoading ? 'Carregando...' : user?.full_name}
              </p>
              <Link to="/users/me">
                <span className="italic font-light text-xs cursor-pointer">Configurações de perfil</span>
              </Link>
            </div>}
          </div>
          {
            isPending
              ? (
                <IconButton
                  Icon={<Spinner className="fill-danger-soft" />}
                />
              )
              : (
                <IconButton
                  Icon={<LogOut color="#FBBCB6" />}
                  onClick={() => logOut()}
                />
              )
          }
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

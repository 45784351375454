import { AreaChart, CarFront, Coins, FileText, Fuel, GitFork, PieChart, Receipt, UserRound } from "lucide-react";

const routes = [
  {
    label: 'Dados gerais',
    Icon: <PieChart strokeWidth={1} />,
    path: '/company',
  },
  {
    label: 'Estrutura',
    Icon: <GitFork strokeWidth={1} />,
    path: '/structure',
  },
  {
    label: 'Financeiro',
    Icon: <Coins strokeWidth={1} />,
    path: [
      { label: 'Transações', Icon: <Receipt strokeWidth={1} />, path: '/billing-events' },
      { label: 'Totalizadores', Icon: <AreaChart strokeWidth={1} />, path: '/movements-totalizer' },
      { label: 'Faturas', Icon: <FileText strokeWidth={1} />, path: '/billing-bills' },
    ]
  },
  {
    label: 'Rede credenciada',
    Icon: <Fuel strokeWidth={1} />,
    path: '/establishments'
  },
  {
    label: 'Equipamentos',
    Icon: <CarFront strokeWidth={1} />,
    path: '/equipments'
  },
  {
    label: 'Usuários',
    Icon: <UserRound strokeWidth={1} />,
    path: '/users'
  },
];

export default routes;

import { ReactNode } from 'react';
import { ColumnPattern } from '.';
import { twMerge } from 'tailwind-merge';

export interface RowsProps {
  data: { [key: string]: unknown; }[];
  columns: ColumnPattern[];
  isLoading: boolean;
  className?: string;
  emptyValue?: string;
}

const Rows = ({ data, columns, isLoading, className, emptyValue = 'Não informado' }: RowsProps) => {
  if (isLoading) {
    return (
      <tr>
        <td colSpan={columns.length}>
          Buscando dados...
        </td>
      </tr>
    );
  }

  if (data.length < 1) {
    return (
      <tr>
        <td colSpan={columns.length}>
          Nenhum dado nessa categoria
        </td>
      </tr>
    );
  }

  return (
    <>
      {data.map((row, i) => (
        <tr key={i} className={twMerge("", className)} >
          {columns.map((column) => (
            <td className={twMerge("py-4 px-5 border-x border-x-white-200 font-normal text-neutral-4 text-left", column.className)} style={{ overflowWrap: 'break-word' }} key={`${column.value}-${i}`}>
              {row[column.value] || row[column.value] === 0 ? (row[column.value] as ReactNode) : emptyValue}
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default Rows;

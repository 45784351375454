import { optionsToStrategy } from "@/common/utils/optionsToStrategy";

export const establishmentBlockModeOptions = [
  {
    label: <p className="text-sm leading-5">Lista de permitidos <span className="font-light text-[10px] italic leading-3">(Essa filial poderá realizar transações apenas nos estabelecimentos selecionados.)</span></p>,
    value: 'allow_list',
  },
  {
    label: <p className="text-sm leading-5">Lista de restritos <span className="font-light text-[10px] italic leading-3">(Essa filial não poderá realizar transações nos estabelecimentos selecionados.)</span></p>,
    value: 'block_list',
  },
];

export const establishmentBlockModeStrategy = optionsToStrategy(establishmentBlockModeOptions);

export const establishmentBlockModeKeyToOption = (key: string): { label: JSX.Element, value: string; } => {
  return ({
    label: establishmentBlockModeStrategy[key as keyof typeof establishmentBlockModeStrategy] || '',
    value: key,
  });
};

import Accordion from "@/common/components/Accordion";
import Checkbox from "@/common/components/Checkbox";
import Select from "@/common/components/Select";
import { RestrictionData } from "../../types/company.type";
import { establishmentOptionTransformer } from "../../services/establishmentOptionTransformer";
import { establishmentBlockModeKeyToOption } from "../../constants/establishmentBlockModeOptions.constant";

type EstablishmentRestrictionProps = {
  restriction?: RestrictionData;
};

export const EstablishmentRestriction = ({
  restriction
}: EstablishmentRestrictionProps) => {
  return (
    <Accordion
      contentClassName="overflow-hidden"
      head={(
        <div className="flex flex-col gap-2 ">
          <Checkbox
            label="Restrição de Estabelecimentos"
            checked={!!restriction?.allowed_or_blocked_establishments?.length}
            name="establishmentRestriction"
            disabled
          />
        </div>
      )}
      body={(
        <div className="flex flex-col gap-4">
          <Select
            label="Tipo de restrição"
            defaultValue={establishmentBlockModeKeyToOption(restriction?.establishment_block_mode || '')}
            placeholder="Não informado"
            isDisabled
          />
          <Select
            label="Estabelecimentos"
            defaultValue={restriction?.allowed_or_blocked_establishments?.map(establishmentOptionTransformer)}
            placeholder="Não informado"
            isMulti
            isDisabled
          />
        </div>
      )}
    />
  );
};

import { ClientUserData } from '@/features/users/types/clientUsers';
import { ArrowBigRightIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

export class ClientUsersTransformer {
  static tableTransformer() {
    return (users: ClientUserData[]) => {
      return users.map((user) => ({
        ...user,
        role: !!user?.user_roles?.length
          ? user?.user_roles
            ?.map((userRole) => userRole.role.display_name)
            .join(', ') || 'Não informado'
          : 'Não informado',
        fullName: user.full_name,
        actions: (
          <Link to={`/users/${user.id}`}>
            <ArrowBigRightIcon />
          </Link>
        ),
      }));
    };
  }
}

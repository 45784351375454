import React from "react";
import { formatCurrency, formatDateTime } from "@/common/utils/formatters";
import { ClientUserEventData } from "../types/events.type";
import FeeDetails from "@/features/billingEvents/components/FeesDetails";
import ConsumptionDetails from "@/features/billingEvents/components/ConsumptionDetails";
import RefundDetails from "@/features/billingEvents/components/RefundDetails";

export class ClientUserEventTransformer {
  static tableTransformer() {
    return (events: ClientUserEventData[]) => {
      return events.map((event) => ({
        status: eventStatusTranslation[event.status] || 'Nao implementado',
        protocol_number: event.protocol_number,
        bill: '',
        eventable_type: eventableTypeTranslations[event.eventable_type] || 'Nao implementado',
        details: eventableDetailsComponent[event.eventable_type] ? React.createElement(eventableDetailsComponent[event.eventable_type], { data: event.eventable }) : '',
        total_amount: event.total_amount ? formatCurrency(event.total_amount) : '',
        fee_total_amount: <FeeDetails {...event} />,
        incode_tax_total_amount: '',
        branch: event.branch_name || '',
        card: event.eventable?.card?.last_four_digits.padStart(8, '*') || '',
        usage_declaration: '',
        author: event?.author?.full_name || '',
        created_at: formatDateTime(new Date(event?.created_at || 0))
      }));
    };
  }
}

const eventableDetailsComponent: { [key: string]: React.ComponentType<any>; } = {
  'Client::Consumption': ConsumptionDetails,
  'Billing::Refund': RefundDetails,
};

const eventStatusTranslation: { [key: string]: string; } = {
  committed: 'Efetivado'
};

const eventableTypeTranslations: { [key: string]: string; } = {
  'Client::Consumption': 'Consumo',
  'Billing::Refund': 'Estorno',
};

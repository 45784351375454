import Box from '@/common/components/Box';
import Button from '@/common/components/Button';
import MaskedTextField from '@/common/components/MaskedTextField';
import SelectController from '@/common/components/SelectController';
import Spinner from '@/common/components/Spinner';
import TextField from '@/common/components/TextField';
import { nameMask, phoneMask } from '@/common/constants/masks.constant';
import { useRoles } from '@/common/hooks/queries/useRoles';
import { useGoBack } from '@/common/hooks/useGoBack';
import { Mapper } from '@/common/services/mapper';
import { RoleData } from '@/common/types/roles';
import { FormTransformers } from '@/common/utils/formTransformers';
import { useCreateClientUser } from '@/features/users/hooks/useCreateClientUser';
import {
  clientUserSchema,
  ClientUserValidationSchema,
} from '@/features/users/schemas/create.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { ChevronLeftIcon } from 'lucide-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { statusOptions } from '../../constants/statusOptions.constant';

const Create = () => {
  const goBack = useGoBack();
  const queryClient = useQueryClient();

  const { data: rolesOptions } = useRoles({
    mapper: Mapper.mapToOptions<RoleData>({
      labelFieldName: 'display_name',
      valueFieldName: 'id',
    }),
  });

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<ClientUserValidationSchema>({
    resolver: zodResolver(clientUserSchema),
  });

  const {
    mutate: createClientUser,
    isPending: createClientUserIsPending,
  } = useCreateClientUser({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clientUsers'] });
      goBack({ fallback: '/users' });
    },
    onError: (error) => {
      FormTransformers.errorsTransformer<ClientUserValidationSchema>({
        setError,
      })(error);
    },
  });

  const onSubmit: SubmitHandler<ClientUserValidationSchema> = (data) => {
    createClientUser(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-4 items-center">
          <Button
            variant="link-white"
            onClick={() => goBack({ fallback: '/users' })}
          >
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Button>
          <h2 className="text-2xl font-bold">Novo usuário</h2>
        </div>
        <Button
          type="submit"
          className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]"
          disabled={createClientUserIsPending}
        >
          {createClientUserIsPending ? (
            <Spinner className="h-6 mx-[23px] fill-white-800" />
          ) : (
            'Confirmar'
          )}
        </Button>
      </div>
      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto max-h-[calc(100vh-128px)]">
        <p className="text-lg font-bold">Dados pessoais</p>
        <div className="flex gap-2 w-full justify-between">
          <div className="w-3/4">
            <MaskedTextField
              label="CPF"
              markAsRequired
              mask="000.000.000-00"
              errorMessage={errors?.cpf?.message || undefined}
              {...register('cpf')}
              disabled={createClientUserIsPending}
            />
            <Button variant="link">Importar dados</Button>
          </div>
          <MaskedTextField
            label="Data de nascimento"
            markAsRequired
            mask="00/00/0000"
            className="w-1/4"
            errorMessage={errors.date_of_birth?.message || undefined}
            {...register('date_of_birth')}
            disabled={createClientUserIsPending}
          />
        </div>
        <MaskedTextField
          label="Nome"
          markAsRequired
          mask={nameMask}
          maxLength={64}
          errorMessage={errors.full_name?.message || undefined}
          {...register('full_name')}
          disabled={createClientUserIsPending}
        />
        <SelectController
          control={control}
          name="user_roles"
          label="Cargo(s)"
          markAsRequired
          errorMessage={errors.user_roles?.message || undefined}
          options={rolesOptions || []}
          isMulti
          isDisabled={createClientUserIsPending}
        />
        <SelectController
          control={control}
          name="status"
          label="Status"
          markAsRequired
          errorMessage={errors.status?.message || undefined}
          options={statusOptions}
          isDisabled={createClientUserIsPending}
        />
        <TextField
          label="Matrícula"
          errorMessage={errors.enrollment_number?.message || undefined}
          {...register('enrollment_number')}
          disabled={createClientUserIsPending}
        />
        <MaskedTextField
          label="Telefone"
          mask={phoneMask}
          errorMessage={errors.phone?.number?.message || undefined}
          {...register('phone.number')}
          disabled={createClientUserIsPending}
        />
        <TextField
          label="Email"
          {...register('email')}
          errorMessage={errors.email?.message || undefined}
          disabled={createClientUserIsPending}
        />
      </Box>
    </form>
  );
};

export default Create;

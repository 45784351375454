import { forwardRef, useEffect, useRef, useState } from 'react';
import TextField, { TextFieldProps } from '../TextField';

export interface CurrencyTextFieldProps extends Omit<TextFieldProps, 'type'> {
  currencySymbol?: string;
  maxValue?: number;
  defaultValue?: string | number;
}

export const CurrencyTextField = forwardRef<HTMLInputElement, CurrencyTextFieldProps>(({
  currencySymbol = 'R$',
  value = '',
  onChange,
  maxValue = 999_999.99,
  defaultValue,
  ...props
}, ref) => {
  const [internalValue, setInternalValue] = useState<string>(defaultValue ? String(defaultValue) : '');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const formatWithThousandSeparator = (number: number) => {
    return number
      .toFixed(2)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const formatDefaultValue = (value: string | number) => {
    if (typeof value === 'string') {
      const numericValue = Number(value.replace(/\D/g, '')) / 100;
      return `${currencySymbol} ${formatWithThousandSeparator(numericValue)}`;
    }

    if (typeof value === 'number') {
      return `${currencySymbol} ${formatWithThousandSeparator(value)}`;
    }

    return `${currencySymbol} 0,00`;
  };

  const handleChange = (e: any) => {
    const inputValue = e.target.value.replace(/\D/g, '');
    let numericValue = Number(inputValue) / 100;

    if (numericValue === 0) {
      setInternalValue('');
      if (onChange) {
        e.target.value = '';
        onChange(e);
      }
      return;
    }

    if (numericValue < 0) {
      numericValue = 0;
    }
    if (maxValue !== undefined && numericValue > maxValue) {
      return;
    }

    const formattedValue = `${currencySymbol} ${formatWithThousandSeparator(numericValue)}`;
    setInternalValue(formattedValue);

    if (onChange) {
      e.target.value = formattedValue;
      onChange(e);
    }

    if (inputRef.current) {
      const inputLength = formattedValue.length;
      inputRef.current.setSelectionRange(inputLength, inputLength);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      const formattedValue = formatDefaultValue(defaultValue);
      setInternalValue(formattedValue);
    }
  }, [defaultValue]);

  return (
    <TextField
      ref={(el) => {
        inputRef.current = el;
        if (typeof ref === 'function') {
          return ref(el);
        }
        if (ref) {
          (ref as React.MutableRefObject<HTMLInputElement | null>).current = el;
        }
      }}
      value={internalValue || value}
      onChange={handleChange}
      placeholder={`${currencySymbol} 0,00`}
      {...props}
    />
  );
},
);

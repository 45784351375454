import usePrivateAxios from "@/common/api/usePrivateAxios";
import { useAuth } from "@/common/stores/auth";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const useLogOut = () => {
  const { updateAccessToken } = useAuth();
  const navigate = useNavigate();
  const privateAxios = usePrivateAxios();

  const { mutate: logOut, ...rest } = useMutation({
    mutationKey: ['logout'],
    mutationFn: async () => {
      return privateAxios.delete('/auth/logout');
    },
    onSuccess: () => {
      localStorage.removeItem('access-token');
      updateAccessToken('');
      navigate('/login');
    }
  });

  return { logOut, ...rest };
};

export default useLogOut;
